body {
  margin: 0;
	font-family: "Century Gothic";
  padding: 4rem 8rem 2rem;
  background-color: #286A8C;
  color: #9CB3C9;
}

h1{
  color: #f06621;
}

h2{
  color: #E17740;
}

.appIcon {
  height: 1.5rem;
  width: 1.5rem;
}

.tableCell {
  padding: .5rem;
}

.profileMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profileDesktop {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.policyMobile {
  padding: 0rem 1rem;
}

.verticalCenter {
  margin: 0;
  top: 50%;
  position: relative;
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

@media screen and (max-width: 1100px){
  body{
      padding: 4rem 0;
  }
}